import { createBrowserRouter } from "react-router-dom";
import Root from "./components/Root";
import BookingDetail from "./routes/BookingDetail";
import BookingEdit from "./routes/BookingEdit";
import GithubConfirm from "./routes/GithubConfirm";
import Home from "./routes/Home";
import KakaoConfirm from "./routes/KakaoConfirm";
import NotFound from "./routes/NotFound";
import RoomDetail from "./routes/RoomDetail";
import RoomEdit from "./routes/RoomEdit";
import UploadPhotos from "./routes/UploadPhotos";
import UploadRoom from "./routes/UploadRoom";
import MyProfile from "./routes/MyProfile";
import EditMyProfile from "./routes/EditMyProfile";
import UserProfile from "./routes/UserProfile";
import MyBooking from "./routes/MyBooking";
import WishList from "./routes/WishList";
import LoginPage from "./routes/LoginPage";
import useUser from "./lib/useUser";
import FindUser from "./routes/FindUser";
import UploadVideos from "./routes/UploadVideos";
import ProtectedPage from "./components/ProtectPage";
import BagTermDetail from "./routes/BagTermDetail";
import BagTerms from "./routes/BagTerms";
import UploadBagTerm from "./routes/UploadBagTerm";
import FindTerm from "./routes/FindTerms/FindTerm";
import FindSynonym1 from "./routes/FindTerms/FindSynonym1";
import FindSynonym2 from "./routes/FindTerms/FindSynonym2";
import FindSynonym3 from "./routes/FindTerms/FindSynonym3";
import FindSynonym4 from "./routes/FindTerms/FindSynonym4";
import FindSynonym10 from "./routes/FindTerms/FindSynonym10";
import FindSynonym9 from "./routes/FindTerms/FindSynonym9";
import FindSynonym8 from "./routes/FindTerms/FindSynonym8";
import FindSynonym7 from "./routes/FindTerms/FindSynonym7";
import FindSynonym6 from "./routes/FindTerms/FindSynonym6";
import FindSynonym5 from "./routes/FindTerms/FindSynonym5";
import BagTermEdit from "./routes/BagTermEdit";
import UploadTermPhoto from "./routes/UploadTermPhoto";
import AboutUs from "./routes/AboutUs";
import AboutMe from "./routes/AboutMe";
import TranslateTerms from "./routes/TranslateTerms";
import BlogList from "./routes/BlogList";
import BlogDetail from "./routes/BlogDetail";
import UploadBlog from "./routes/UploadBlog";
import BlogEdit from "./routes/BlogEdit";
import UploadPostPhoto from "./components/UploadPostPhoto";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <NotFound />,
    children: [
      {
        path: "",
        element: <LoginPage />
      },
      {
        path: "home",
        element: (
          <ProtectedPage>
            <Home />
          </ProtectedPage>
        )
      },
      {
        path: "terms",
        children: [
          {
            path: "",
            element: <BagTerms />
          },
          {
            path: ":termId",
            element: <BagTermDetail />
          },
          {
            path: ":termId/edit",
            element: <BagTermEdit />
          },
          {
            path: "upload",
            children: [
              {
                path: "",
                element: <UploadBagTerm />
              },
              { path: ":termId/photo", element: <UploadTermPhoto /> }
            ]
          },
          {
            path: "termsList",
            children: [
              {
                path: "",
                element: <FindTerm />
              },
              {
                path: "synonym1",
                element: <FindSynonym1 />
              },
              {
                path: "synonym2",
                element: <FindSynonym2 />
              },
              {
                path: "synonym3",
                element: <FindSynonym3 />
              },
              {
                path: "synonym4",
                element: <FindSynonym4 />
              },
              {
                path: "synonym5",
                element: <FindSynonym5 />
              },
              {
                path: "synonym6",
                element: <FindSynonym6 />
              },
              {
                path: "synonym7",
                element: <FindSynonym7 />
              },
              {
                path: "synonym8",
                element: <FindSynonym8 />
              },
              {
                path: "synonym9",
                element: <FindSynonym9 />
              },
              {
                path: "synonym10",
                element: <FindSynonym10 />
              }
            ]
          }
        ]
      },
      {
        path: "aboutus",
        children: [
          {
            path: "",
            element: <AboutUs />
          },
          {
            path: "aboutme",
            element: <AboutMe />
          }
        ]
      },
      {
        path: "users",
        children: [
          {
            path: "",
            element: <FindUser />
          },
          {
            path: "mybookings",
            element: <MyBooking />
          },
          {
            path: "wishlist",
            element: <WishList />
          },
          {
            path: "me",
            element: <MyProfile />
          },
          {
            path: "me/edit",
            element: <EditMyProfile />
          },
          {
            path: "login",
            element: <LoginPage />
          },
          {
            path: ":userId",
            element: <UserProfile />
          }
        ]
      },
      {
        path: "bookings",
        children: [
          {
            path: ":bookingId",
            element: <BookingDetail />
          },
          { path: ":bookingId/edit", element: <BookingEdit /> }
        ]
      },
      {
        path: "rooms",
        children: [
          {
            path: "upload",
            element: <UploadRoom />
          },
          {
            path: ":roomPk",
            element: <RoomDetail />
          },
          {
            path: ":roomPk/photos",
            element: <UploadPhotos />
          },
          {
            path: ":roomPk/videos",
            element: <UploadVideos />
          },
          {
            path: ":roomPk/edit",
            element: <RoomEdit />
          }
        ]
      },
      {
        path: "social",
        children: [
          {
            path: "github",
            element: <GithubConfirm />
          },
          {
            path: "kakao",
            element: <KakaoConfirm />
          }
        ]
      },
      {
        path: "translate",
        element: <TranslateTerms />
      },
      {
        path: "blog",
        children: [
          {
            path: "",
            element: <BlogList />
          },
          {
            path: ":blogId",
            element: <BlogDetail />
          },
          {
            path: ":blogId/edit",
            element: <BlogEdit />
          },

          {
            path: "upload",
            element: <UploadBlog />
          }
        ]
      }
    ]
  }
]);

export default router;
