import { Box, useColorModeValue } from "@chakra-ui/react";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import Navbar from "./Navbar";
import { useEffect } from "react";
import { GiTurtle } from "react-icons/gi";
import { renderToStaticMarkup } from "react-dom/server";

export default function Root() {
  // 페이지가 로드될 때, favicon을 설정
  useEffect(() => {
    // SVG 아이콘을 문자열로 변환
    const svgString = encodeURIComponent(renderToStaticMarkup(<GiTurtle />));
    const svgUrl = `data:image/svg+xml,${svgString}`;

    // 캔버스를 생성하고 SVG를 그린 후, 데이터 URL로 변환
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.naturalWidth;
      canvas.height = img.naturalHeight;
      const ctx = canvas.getContext("2d");
      if (ctx) {
        ctx.drawImage(img, 0, 0);
        const faviconUrl = canvas.toDataURL("image/png");

        // Favicon 설정
        let link = document.querySelector(
          "link[rel*='icon']"
        ) as HTMLLinkElement;
        if (!link) {
          link = document.createElement("link");
          link.rel = "icon";
          document.getElementsByTagName("head")[0].appendChild(link);
        }
        link.href = faviconUrl;
      }
    };
    img.src = svgUrl;
  }, []);

  const bgColor = useColorModeValue("gray.50", "gray.900");

  return (
    <Box backgroundColor={bgColor}>
      <Navbar />
      <Outlet />
      <Footer />
      <ReactQueryDevtools />
    </Box>
  );
}
