import { Link, Text, useColorModeValue } from "@chakra-ui/react";

// NavLink Component
interface NavLinkProps {
  name: string;
  path: string;
  onClose: () => void;
}

export default function NavLink({ name, path, onClose }: NavLinkProps) {
  return (
    <Link href={path} lineHeight="inherit" onClick={() => onClose()}>
      <Text
        color={useColorModeValue("gray.600", "gray.400")}
        _hover={{
          textDecoration: "none",
          color: useColorModeValue("blue.500", "blue.200")
        }}
      >
        {name}
      </Text>
    </Link>
  );
}
