import { useQuery } from "@tanstack/react-query";
import { getMe } from "../api";
import { IUser } from "../types";

export default function useUser() {
  const { isLoading, data, isError } = useQuery<IUser>({
    queryKey: [`me`],
    queryFn: getMe,
    retry: false
  });

  return {
    userLoading: isLoading,
    user: data,
    isLoggedIn: !isError // 로그인이 되어 있으면 에러가 발생하지 않으므로 false를 반환하므로
  };
}