import { QueryFunctionContext } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";
import { formatDate } from "./lib/utils";
// import Cookies from "js-cookie";
import {
  IBlog,
  IBlogForm,
  IBlogListResponse,
  IBookingDetail,
  IBookingList,
  IData,
  IEnglish,
  IReviewResponse,
  IRoomDetail,
  IRoomList,
  ITerm,
  ITermListResponse,
  ITranslateVariables,
  IUser
} from "./types";

// axios 인스턴스 생성
const instance = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://127.0.0.1:8000/api/v1/"
      : "https://backend.bagterm.com/api/v1/",
  withCredentials: true
});

// 룸 리스트 가져오기
export const getRooms = async ({
  queryKey
}: QueryFunctionContext): Promise<IRoomList[]> => {
  // queryKey로부터 currentPage, searchQuery, selectedCategory를 가져온다.
  const [_, currentPage, searchQuery, selectedCategory] = queryKey;
  const currentPageString = currentPage ? `page=${currentPage}` : "";

  // 검색어가 있으면, 검색어를 쿼리스트링으로 만들어준다.
  const queryString = searchQuery ? `&search=${searchQuery}` : "";
  // 카테고리가 있으면, 카테고리를 쿼리스트링으로 만들어준다.
  const categoryString = selectedCategory
    ? `&category=${selectedCategory}`
    : "";
  try {
    const response = await instance.get(
      `rooms?${currentPageString}${queryString}${categoryString}`
    );
    return response.data as IRoomList[];
  } catch (error) {
    throw new Error("Failed to fetch room details");
  }
};

// 룸 디테일 데이터 가져오기
export const getRoom = async ({
  queryKey
}: QueryFunctionContext): Promise<IRoomDetail> => {
  const [_, roomPk] = queryKey;
  try {
    const response = await instance.get(`rooms/${roomPk}`);
    return response.data as IRoomDetail;
  } catch (error) {
    throw new Error("Failed to fetch room details");
  }
};

// 룸 리뷰 가져오기
export const getRoomReviews = async ({
  queryKey
}: QueryFunctionContext): Promise<IReviewResponse> => {
  const [_, roomPk, __, currentPage] = queryKey;
  try {
    const response = await instance.get(
      `rooms/${roomPk}/reviews?page=${currentPage}`
    );
    return response.data as IReviewResponse;
  } catch (error) {
    throw new Error("Failed to fetch review details.");
  }
};

interface ICreateReviewVariables {
  roomPk: string;
  payload: string;
  rating: number;
}

// 리뷰 작성 API
export const createReview = (variables: ICreateReviewVariables) =>
  instance
    .post(`rooms/${variables.roomPk}/reviews`, variables, {
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken") || ""
      }
    })
    .then((response) => response.data);

interface IEditReviewVariables {
  reviewId: number;
  payload: string;
  rating: number;
  roomPk: string; // 이 줄을 추가
}

// 리뷰 편집 API
export const editReview = (variables: IEditReviewVariables) =>
  instance
    .put(`rooms/${variables.roomPk}/reviews/${variables.reviewId}`, variables, {
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken") || ""
      }
    })
    .then((response) => response.data);

// 리뷰 삭제 API
export const deleteReview = (reviewId: number, roomPk: string) =>
  instance
    .delete(`rooms/${roomPk}/reviews/${reviewId}`, {
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken") || ""
      }
    })
    .then((response) => response.data);

// 내 정보 가져오기
export const getMe = () =>
  instance.get(`users/me`).then((response) => response.data);

export interface IEditProfileVariables {
  name: string;
  email: string;
  avatar: string;
  password: string;
  username: string;
}

// user 정보 가져오기
export const getUserInformation = async ({
  queryKey
}: QueryFunctionContext): Promise<IUser> => {
  const [_, userId] = queryKey;
  console.log(userId);
  try {
    const response = await instance.get(`users/${userId}`);
    return response.data as IUser;
  } catch (error) {
    throw new Error("Failed to fetch room details");
  }
};

// users 리스트 가져오기
export const getUsers = async ({
  queryKey
}: QueryFunctionContext): Promise<IData> => {
  const [_, currentPage, searchQuery] = queryKey;
  console.log(currentPage);
  const queryString = searchQuery ? `&search=${searchQuery}` : "";
  try {
    const response = await instance.get(
      `users?page=${currentPage}${queryString}`
    );
    return response.data as IData;
  } catch (error) {
    throw new Error("Failed to fetch room details");
  }
};

// 내 정보 수정
export const editProfile = (variables: IEditProfileVariables) =>
  instance
    .put(`users/me`, variables, {
      headers: { "X-CSRFToken": Cookies.get("csrftoken") || "" }
    })
    .then((response) => response.data);

export interface IChangePasswordVariables {
  oldPassword: string;
  newPassword: string;
}

// 비밀번호 변경
export const changePassword = (variables: IChangePasswordVariables) =>
  instance
    .put(`users/change-password`, variables, {
      headers: { "X-CSRFToken": Cookies.get("csrftoken") || "" }
    })
    .then((response) => response.data);

// 로그아웃
export const logOut = () =>
  instance
    .post(`users/log-out`, null, {
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken") || ""
      }
    })
    .then((response) => response.data);

// github 로그인
export const githubLogin = (code: string) =>
  instance
    .post(
      `/users/github`,
      { code },
      {
        headers: {
          "X-CSRFToken": Cookies.get("csrftoken") || ""
        }
      }
    )
    .then((response) => response.data);

// Kakao 로그인
export const KakaoLogin = (code: string) =>
  instance
    .post(
      `/users/kakao`,
      { code },
      {
        headers: {
          "X-CSRFToken": Cookies.get("csrftoken") || ""
        }
      }
    )
    .then((response) => response.data);

export interface IUsernameLoginVariables {
  username: string;
  password: string;
}

export interface IUsernameLoginSuccess {
  ok: string;
}

export interface IUsernameLoginError {
  error: string;
}

// 아이디/비번 로그인
export const usernameLogin = async ({
  username,
  password
}: IUsernameLoginVariables) => {
  try {
    const response = await instance.post(
      `/users/log-in`,
      { username, password },
      {
        headers: {
          "X-CSRFToken": Cookies.get("csrftoken") || ""
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export interface IUserSignupVariables {
  name: string;
  username: string;
  email: string;
  password: string;
  password2: string;
}

export interface IUserSignupSuccess {
  ok: string;
}

export interface IUserSignupError {
  error: string;
}

// 회원가입
export const userSignup = ({
  name,
  username,
  email,
  password,
  password2
}: IUserSignupVariables) =>
  instance
    .post(
      `/users/sign-up`,
      { name, username, email, password, password2 },
      {
        headers: {
          "X-CSRFToken": Cookies.get("csrftoken") || ""
        }
      }
    )
    .then((response) => response.data);

// 방 아메니티 가져오기 API
export const getAmenities = () =>
  instance.get(`rooms/amenities`).then((response) => response.data);

// 방 카테고리 가져오기 API
export const getRoomCategories = () =>
  instance.get(`categories/rooms`).then((response) => response.data);

export interface IUploadRoomVariables {
  name: string;
  country: string;
  city: string;
  price: number;
  rooms: number;
  toilets: number;
  description: string;
  address: string;
  pet_friendly: boolean;
  kind: string;
  amenities: number[];
  category: number[];
  manager: string;
}

// 방 업로드 API
export const uploadRoom = (variables: IUploadRoomVariables) =>
  instance
    .post(`rooms/`, variables, {
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken") || ""
      }
    })
    .then((response) => response.data);

// 방 비디오 업로드 URL 가져오기 API
export const getUploadVideoURL = () =>
  instance
    .post(`medias/videos/get-url`, null, {
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken") || ""
      }
    })
    .then((response) => response.data);

// 클라우드플레어 이미지 업로드 URL 가져오기 API
export const getUploadURL = () =>
  instance
    .post(`medias/photos/get-url`, null, {
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken") || ""
      }
    })
    .then((response) => response.data);

export interface IUploadVideoVariables {
  file: FileList;
  uploadURL: string;
}

// 클라우드플레어에 비디오 업로드 후 비디오 데이터 가져오기 API
export const getVideoData = (UID: string) => {
  console.log("uid", UID);
  return instance
    .post(
      `medias/videos/data`,
      { uid: UID },
      {
        headers: {
          "X-CSRFToken": Cookies.get("csrftoken") || ""
        }
      }
    )
    .then((response) => {
      console.log("Upload response:", response.data); // Check the response
      return response.data;
    })
    .catch((error) => {
      console.error("Upload error:", error); // Catch and log any error
    });
};




// 클라우드플레어 비디오 업로드 API
export const uploadVideo = async ({
  file,
  uploadURL
}: IUploadVideoVariables) => {
  // 자바스크립트 폼 만들기
  const form = new FormData();
  form.append("file", file[0]); // key, value 쌍으로 form 데이터 추가

  // 파일 크기가 200MB를 초과하는 경우 에러 처리
  if (file[0].size > 200 * 1024 * 1024) {
    console.error("File size exceeds the 200MB limit.");
    return;
  }

  try {
    const response = await axios.post(uploadURL, form, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });

    // 업로드가 성공적으로 이루어진 경우 200 상태 코드를 반환
    if (response.status === 200) {
      console.log("Response", response);
      console.log("Upload successful:", response.data);
      return response.data;
    } else {
      // 업로드 제약 조건을 충족하지 못하거나 파일 크기가 200MB를 초과하는 경우 4xx 상태 코드를 반환
      console.error("Upload failed with status:", response.status);
    }
  } catch (error) {
    console.error("Upload error:", error); // Catch and log any error
    throw error; // Re-throw the error to be caught by the calling function
  }
};

export interface IUploadImageVariables {
  file: FileList;
  uploadURL: string;
}

// 클라우드플레어 이미지 업로드 API
export const uploadImage = ({ file, uploadURL }: IUploadImageVariables) => {
  // 자바스크립트 폼 만들기
  const form = new FormData();
  form.append("file", file[0]); // key, value 쌍으로 form 데이터 추가
  return axios
    .post(uploadURL, form, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
    .then((response) => {
      console.log("Upload response:", response.data); // Check the response
      return response.data;
    })
    .catch((error) => {
      console.error("Upload error:", error); // Catch and log any error
    });
};

export interface ICreateVideoVariables {
  VideoFile: string;
  ThumbnailFile: string;
  roomPk: string;
}

// 방 비디오 서버 DB에 저장 API
export const createVideo = ({
  VideoFile,
  ThumbnailFile,
  roomPk
}: ICreateVideoVariables) =>
  instance
    .post(
      `rooms/${roomPk}/videos`,
      { VideoFile, ThumbnailFile },
      {
        headers: {
          "X-CSRFToken": Cookies.get("csrftoken") || ""
        }
      }
    )
    .then((response) => response.data);

export interface ICreatePostVideoVariables {
  VideoFile: string;
  ThumbnailFile: string;
  blogId: string;
  description: string;
}

// 블로 포스트 비디오 서버 DB에 저장 API
export const createPostVideo = ({
  VideoFile,
  ThumbnailFile,
  blogId,
  description
}: ICreatePostVideoVariables) =>
  instance
    .post(
      `blogs/${blogId}/videos`,
      { VideoFile, ThumbnailFile, description },
      {
        headers: {
          "X-CSRFToken": Cookies.get("csrftoken") || ""
        }
      }
    )
    .then((response) => response.data);


export interface ICreatePhotoVariables {
  description: string;
  file: string;
  roomPk: string;
}

// 사진 업로드 API
export const createPhoto = ({
  description,
  file,
  roomPk
}: ICreatePhotoVariables) =>
  instance
    .post(
      `rooms/${roomPk}/photos`,
      { description, file },
      {
        headers: {
          "X-CSRFToken": Cookies.get("csrftoken") || ""
        }
      }
    )
    .then((response) => response.data);

interface ICreatePhotoTermVariables {
  description: string;
  file: string;
  termId: string;
}

export const createTermPhoto = ({
  description,
  file,
  termId
}: ICreatePhotoTermVariables) =>
  instance
    .post(
      `bagterms/${termId}/photos`,
      { description, file },
      {
        headers: {
          "X-CSRFToken": Cookies.get("csrftoken") || ""
        }
      }
    )
    .then((response) => response.data);

interface ICreatePostPhotoVariables {
  description: string;
  file: string;
  blogId: string;
}

export const createPostPhoto = ({
  description,
  file,
  blogId
}: ICreatePostPhotoVariables) =>
  instance
    .post(
      `blogs/${blogId}/photos`,
      { description, file },
      {
        headers: {
          "X-CSRFToken": Cookies.get("csrftoken") || ""
        }
      }
    )
    .then((response) => response.data);

type checkBookingQueryKey = [string, string?, Date[]?];

// 방 예약 가능 여부 확인 API
export const checkBooking = ({
  queryKey
}: QueryFunctionContext<checkBookingQueryKey>) => {
  const [_, roomPk, dates] = queryKey;
  if (dates) {
    const [firstDate, secondDate] = dates;
    const checkIn = formatDate(firstDate);
    console.log(checkIn, firstDate);
    const checkOut = formatDate(secondDate);
    return instance
      .get(
        `rooms/${roomPk}/bookings/check?check_in=${checkIn}&check_out=${checkOut}`
      )
      .then((response) => response.data);
  }
};

export interface IRoomBookingVariables {
  roomPk: string;
  check_in: string;
  check_out: string;
  guests: number;
  kind: string;
}

export interface IRoomBookingSuccess {
  check_in: string;
  check_out: string;
}

// 방 예약 API
export const roomBooking = (variables: IRoomBookingVariables) =>
  instance
    .post(`rooms/${variables.roomPk}/bookings`, variables, {
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken") || ""
      }
    })
    .then((response) => response.data);

// 방 수정 변수
export interface IEditRoomVariables {
  roomPk: string;
  name: string;
  country: string;
  city: string;
  price: number;
  rooms: number;
  toilets: number;
  description: string;
  address: string;
  pet_friendly: boolean;
  kind: string;
  amenities: number[];
  category: number;
}

// 방 수정 API
export const editRoom = (variables: IEditRoomVariables) =>
  instance
    .put(`rooms/${variables.roomPk}`, variables, {
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken") || ""
      }
    })
    .then((response) => response.data);

// 방 삭제 API
export const deleteRoom = (roomPk: string) =>
  instance
    .delete(`rooms/${roomPk}`, {
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken") || ""
      }
    })
    .then((response) => response.data);

// 사용자 예약 가져오기 API
export const getUserBookings = async ({
  queryKey // queryKey: [string, string?, Date[]?]
}: QueryFunctionContext): Promise<IBookingList> => {
  const [_, currentPage] = queryKey;
  try {
    const response = await instance.get(`users/bookings?page=${currentPage}`);
    return response.data as IBookingList;
  } catch (error) {
    throw new Error("Failed to fetch booking details");
  }
};

// 예약 삭제 API
export const removeBooking = (bookingId: number) =>
  instance
    .delete(`bookings/${bookingId}`, {
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken") || ""
      }
    })
    .then((response) => response.data);

// 예약 가져오기 API
export const getBooking = async ({
  queryKey
}: QueryFunctionContext): Promise<IBookingDetail> => {
  const [_, bookingId] = queryKey;
  try {
    const response = await instance.get(`bookings/${bookingId}`);
    return response.data as IBookingDetail;
  } catch (error) {
    throw new Error("Failed to fetch booking details");
  }
};

// 예약 수정 API
export const editBooking = (variables: IBookingDetail) =>
  instance
    .put(`bookings/${variables.pk}`, variables, {
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken") || ""
      }
    })
    .then((response) => response.data);

export interface IFilePhotos {
  pk: string;
  name: string;
  type: string;
  file: string;
}

// 사진 가져오기 API
export const getRoomPhotos = async ({
  queryKey
}: QueryFunctionContext): Promise<IFilePhotos[]> => {
  const [_, roomPk] = queryKey;
  try {
    const response = await instance.get(`rooms/${roomPk}/photos`);
    return response.data as IFilePhotos[];
  } catch (error) {
    throw new Error("Failed to fetch room files");
  }
};

// Bagterm 사진 가져오기 API
export const getTermPhotos = async ({
  queryKey
}: QueryFunctionContext): Promise<IFilePhotos[]> => {
  const [_, termId] = queryKey;
  try {
    const response = await instance.get(`bagterms/${termId}/photos`);
    return response.data as IFilePhotos[];
  } catch (error) {
    throw new Error("Failed to fetch room files");
  }
};

// Blog Post 사진 가져오기 API
export const getPostPhotos = async ({
  queryKey
}: QueryFunctionContext): Promise<IFilePhotos[]> => {
  const [_, blogId] = queryKey;
  try {
    const response = await instance.get(`blogs/${blogId}/photos`);
    return response.data as IFilePhotos[];
  } catch (error) {
    throw new Error("Failed to fetch room files");
  }
}

export interface IFileVideos {
  VideoFile: string;
  ThumbnailFile: string;
  description: string;
  pk: string;
}

// Post 비디오 가져오기 API
export const getPostVideo = ({
  queryKey
}: QueryFunctionContext): Promise<IFileVideos[]> => {
  const [_, blogId] = queryKey;
  return instance.get(`blogs/${blogId}/videos`).then((response) => response.data);
}

// 사진 삭제 API
export const deletePhoto = (photoPk: string) =>
  instance
    .delete(`medias/photos/${photoPk}`, {
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken") || ""
      }
    })
    .then((response) => response.data);

// 비디오 삭제 API
export const deleteVideo = (videoPk: string) =>
  instance
    .delete(`medias/videos/${videoPk}`, {
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken") || ""
      }
    })
    .then((response) => response.data);

// 방 wishlist 추가 API
export const createWishlist = (roomPk: string) =>
  instance
    .post(
      `users/wishlists`,
      { rooms: [roomPk] }, // 배열로 넣어준다.
      {
        headers: {
          "X-CSRFToken": Cookies.get("csrftoken") || ""
        }
      }
    )
    .then((response) => {
      // 여기서는 response.data를 반환하지 않는다.
      return response;
    });

// 방 wishlist check API
export const checkWishlist = (roomPk: string) =>
  instance
    .get(`wishlists/rooms/check/${roomPk}`)
    .then((response) => response.data);

// user wishlist 가져오기 API
export const getUserWishlists = () =>
  instance.get(`users/wishlists`).then((response) => response.data);

// Bag English Terminologies 가져오기 API
export const getEnglishBagTerminologies = async () => {
  try {
    const response = await instance.get(`terminologies/english`);
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch bag terminologies");
  }
};

// Bag English Terminology Detail 가져오기 API
export const getEnglishBagTermDetail = async ({
  queryKey
}: QueryFunctionContext): Promise<IEnglish> => {
  const [_, __, termId] = queryKey;
  console.log(termId);
  try {
    const response = await instance.get(`terminologies/english/${termId}`);
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch bag terminologies");
  }
};

// BagTerms 가져오기 API
export const getBagTerms = async ({
  queryKey
}: QueryFunctionContext): Promise<ITermListResponse> => {
  const [_, currentPage, searchQuery] = queryKey;
  // 페이지 번호가 있으면, 페이지 번호를 쿼리스트링으로 만들어준다.
  const currentPageString = currentPage ? `page=${currentPage}` : "";
  // 검색어가 있으면, 검색어를 쿼리스트링으로 만들어준다.
  const queryString = searchQuery ? `&search=${searchQuery}` : "";

  try {
    const response = await instance.get(
      `bagterms?${currentPageString}${queryString}`
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch bag terminologies");
  }
};

// BagTermDetail 가져오기 API
export const getBagTermDetail = async ({
  queryKey
}: QueryFunctionContext): Promise<ITerm> => {
  const [_, termId] = queryKey;
  try {
    const response = await instance.get(`bagterms/${termId}`);
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch bag terminologies");
  }
};

// BagTerm 업로드 API
export const uploadBagTerm = (variables: ITerm) =>
  instance
    .post(`bagterms/`, variables, {
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken") || ""
      }
    })
    .then((response) => response.data);

// BagTerm 수정 API
export const editBagTerm = (variables: ITerm) =>
  instance
    .put(`bagterms/${variables.id}`, variables, {
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken") || ""
      }
    })
    .then((response) => response.data);

// BagTerm 삭제 API
export const deleteBagTerm = (termId: string) =>
  instance
    .delete(`bagterms/${termId}`, {
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken") || ""
      }
    })
    .then((response) => response.data);

// Translation API
export const translate = async (variables: ITranslateVariables) => {
  try {
    const response = await instance.post(`bagterms/translate`, variables, {
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken") || ""
      }
    });
    return response.data;
  } catch (error) {
    throw new Error("Failed to translate text");
  }
};

// 번역을 위한 Text에 포함된 BagTerm 용어 가져오기 API
export const getBagTermInText = async (text: string) => {
  try {
    const response = await instance.post(
      `bagterms/translate/extract`,
      { text },
      {
        headers: {
          "X-CSRFToken": Cookies.get("csrftoken") || ""
        }
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to extract bag terms from text");
  }
};


// Blog 리스트 가져오기
export const getBlogs = async ({
  queryKey
}: QueryFunctionContext): Promise<IBlogListResponse> => {
  const [_, currentPage, searchQuery] = queryKey;
  // 페이지 번호가 있으면, 페이지 번호를 쿼리 스트링으로 만들어준다.
  const currentPageString = currentPage ? `page=${currentPage}` : "";
  // 검색어가 있으면, 검색어를 쿼리 스트링으로 만들어준다.
  const queryString = searchQuery ? `&search=${searchQuery}` : "";
  try {
    const response = await instance.get(`blogs?${currentPageString}${queryString}`);
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch blog");
  }
};

// Blog Detail 가져오기
export const getBlogDetail = async ({
  queryKey
}: QueryFunctionContext): Promise<IBlog> => {
  try {
    const [_, blogId] = queryKey;
    const response = await instance.get(`blogs/${blogId}`);
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch blog detail");
  }
};


// Blog 업로드 API
export const uploadBlog = (variables: IBlogForm) =>
  instance
    .post(`blogs/`, variables, {
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken") || ""
      }
    })
    .then((response) => response.data);

// blog 수정 API
export const editBlog = (variables: IBlogForm) =>
  instance
    .put(`blogs/${variables.id}`, variables, {
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken") || ""
      }
    })
    .then((response) => response.data);

// blog 삭제 API
export const deleteBlog = (blogId: string) =>
  instance
    .delete(`blogs/${blogId}`, {
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken") || ""
      }
    })
    .then((response) => response.data);