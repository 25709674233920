import {
  Box,
  Flex,
  HStack,
  Button,
  Text,
  Link,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Stack,
  Icon,
  IconButton,
  useDisclosure,
  useColorModeValue,
  useColorMode,
  useToast,
  ToastId,
  LightMode,
  Avatar
} from "@chakra-ui/react";
// Here we have used react-icons package for the icons
import { RiFlashlightFill, BiChevronDown } from "react-icons/all";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import NavLink from "./NavLink";
import MenuLink from "./MenuLink";
import useUser from "../lib/useUser";
import { FaFrog, FaMoon, FaSun } from "react-icons/fa";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useRef } from "react";
import { logOut } from "../api";
import LoginModal from "./LoginModal";
import SignUpModal from "./SignUpModal";
import { useLocation } from "react-router-dom";
import LanguageSwitcher from "./LanguageSwitcher";
import { GiTurtle } from "react-icons/gi";

// 상단 페이지 링크.
const navLinks = [
  { name: "Home", path: "/home" },
  { name: "Translation", path: "/translate" },
  { name: "Bag Terminology", path: "/terms" },
  { name: "About Us", path: "/aboutus" },
  { name: "Tech Blog", path: "/blog" }
];

// 현재 Community와 같이 만약 드랍다운 메뉴를 가진 페이지가 있다면, 드랍다운 메뉴에 들어갈 링크들을 정의
const dropdownLinks = [
  {
    name: "Style List",
    path: "#"
  },
  {
    name: "Order List",
    path: "#"
  },
  {
    name: "Production Schedule",
    path: "#"
  },
  {
    name: "Organization",
    path: "#"
  }
];

export default function Navbar() {
  // 모바일 화면 햄버거 메뉴
  const { isOpen, onOpen, onClose } = useDisclosure();
  // Call useColorModeValue at the top level of the component
  const backgroundColor = useColorModeValue("gray.50", "gray.800");
  // Community 드랍다운 메뉴 배경색
  const menuListBackgroundColor = useColorModeValue(
    "rgb(255, 255, 255)",
    "rgb(26, 32, 44)"
  );
  // Community 드랍다운 메뉴 박스 쉐도우
  const boxShadowColor = useColorModeValue(
    "2px 4px 6px 2px rgba(160, 174, 192, 0.6)",
    "2px 4px 6px 2px rgba(9, 17, 28, 0.6)"
  );

  // 페이지 링크 comunity 메뉴 텍스트 색상
  const dropdownMenuTextColor = useColorModeValue("gray.600", "gray.400");

  // 로그인 여부에 따라서 로그인 버튼과 아바타가 나타나도록 현재 로그인한 user 가져오기
  const { userLoading, isLoggedIn, user } = useUser();

  // 로그인 모달
  const {
    isOpen: isLoginOpen,
    onClose: onLoginClose,
    onOpen: onLoginOpen
  } = useDisclosure();

  // 회원가입 모달
  const {
    isOpen: isSignUpOpen,
    onClose: onSignUpClose,
    onOpen: onSignUpOpen
  } = useDisclosure();

  // 다크모드 토글
  const { toggleColorMode } = useColorMode();
  const logoColor = useColorModeValue("gray.700", "gray.200");
  const Icon = useColorModeValue(FaMoon, FaSun);
  const toast = useToast();
  const queryClient = useQueryClient();
  const toastId = useRef<ToastId>();

  // 로그아웃 mutation
  const mutation = useMutation({
    mutationFn: logOut,
    onMutate: () => {
      toastId.current = toast({
        title: "Login out...",
        description: "Sad to see you go...",
        status: "loading",
        position: "bottom-right"
      });
    },
    onSuccess: () => {
      if (toastId.current) {
        queryClient.refetchQueries({ queryKey: [`me`] });
        toast.update(toastId.current, {
          status: "success",
          title: "Done!",
          description: "See you later!"
        });
      }
    }
  });
  // 로그아웃 버튼 클릭시
  const onLogOut = async () => {
    mutation.mutate();
  };

  // 아래 로그인 페이지에서 Navbar를 보여주지 않기 위해 현재 페이지 경로 가져오기
  const location = useLocation();

  // 로그인 페이지와 manager 선택창에서는 Navbar를 보여주지 않음
  // 로그인 페이지와 manager 선택창에서는 Navbar를 보여주지 않음
  if (
    location.pathname === "/" ||
    location.pathname === "/users" ||
    location.pathname === "/terms/termsList" ||
    // 사진 업로드 페이지에서는 Navbar를 보여주지 않음. :termId 로는 사진 업로드 페이지를 구분할 수 없기 때문에 /photo로 끝나는 경로에서는 Navbar를 보여주지 않음
    (location.pathname.startsWith("/terms/upload/") &&
      location.pathname.endsWith("/photo"))
  ) {
    return null;
  }

  return (
    <Box
      py={"5"}
      px={{
        base: "5",
        md: "10"
      }}
      borderBottomWidth={"1"}
      bg={backgroundColor}
    >
      <Flex
        h={16}
        alignItems="center"
        justifyContent={"space-between"}
        mx="auto"
      >
        {/* 왼쪽 로고, 페이지 링크, 드랍다운 메뉴 */}
        <Box display={"flex"} justifyContent={"flex-start"}>
          {/* Mobile Screen Menu Icon */}
          <IconButton
            size="md"
            icon={isOpen ? <AiOutlineClose /> : <GiHamburgerMenu />}
            aria-label="Open Menu"
            display={{ base: "inherit", md: "none" }}
            onClick={isOpen ? onClose : onOpen}
          />
          {/* 로고 */}
          <Link href="/home">
            <Box
              mr={"5"}
              color={logoColor}
              display={{ base: "none", md: "block" }}
            >
              <GiTurtle size="34px" />
            </Box>
          </Link>
          {/* Desktop Screen 페이지 Links */}
          <HStack spacing={8} alignItems="center">
            <HStack
              as="nav"
              spacing={6}
              display={{ base: "none", md: "flex" }}
              alignItems="center"
            >
              {navLinks.map((link, index) => (
                <NavLink key={index} {...link} onClose={onClose} />
              ))}

              {/* Dropdown Menu */}
              <Menu autoSelect={false} isLazy>
                {({ isOpen, onClose }) => (
                  <>
                    <MenuButton _hover={{ color: "blue.400" }}>
                      <Flex alignItems="center">
                        <Text color={dropdownMenuTextColor}>Production</Text>
                        <BiChevronDown
                          size="20px"
                          style={{
                            marginLeft: "4px",
                            transition: "all .25s ease-in-out",
                            transform: isOpen ? "rotate(180deg)" : ""
                          }}
                        />
                      </Flex>
                    </MenuButton>
                    <MenuList
                      zIndex={5}
                      bg={menuListBackgroundColor}
                      border="none"
                      boxShadow={boxShadowColor}
                    >
                      {dropdownLinks.map((link, index) => (
                        <MenuLink
                          key={index}
                          name={link.name}
                          path={link.path}
                          onClose={onClose}
                        />
                      ))}
                    </MenuList>
                  </>
                )}
              </Menu>
            </HStack>
          </HStack>
        </Box>
        {/* 오른쪽 언어변경, 로그인 버튼, 아바타 */}
        <Box>
          <HStack
            spacing={{
              base: "0.5",
              md: "1.5"
            }}
          >
            {/* 다크 모드 토글 */}
            <IconButton
              onClick={toggleColorMode}
              variant={"ghost"}
              aria-label="Toggle dark mode"
              icon={<Icon />}
            ></IconButton>
            {/* // 언어 변경 버튼 */}
            <LanguageSwitcher />

            {/* user 로딩이 안되면 null 페이지로 */}
            {!userLoading ? (
              // 로그인 안되면 버튼이 나타나고 로그인시에만 아바타가 나타나도록
              !isLoggedIn ? (
                <>
                  <Button onClick={onLoginOpen}>Log in</Button>
                  <LightMode>
                    <Button onClick={onSignUpOpen} colorScheme={"red"}>
                      Sign up
                    </Button>
                  </LightMode>
                </>
              ) : (
                <Menu>
                  {/* 로그인 user 아바타 드랍다운 버튼 */}
                  <MenuButton>
                    <Avatar name={user?.name} src={user?.avatar} size={"sm"} />
                  </MenuButton>
                  {/* 로그인 후 아바타 클릭시 드랍다운 사용자 페이지 */}
                  <MenuList>
                    <Link href={"/users/me"}>
                      <MenuItem>My Profile</MenuItem>
                    </Link>
                    {user?.is_host ? (
                      <Link href={"/rooms/upload"}>
                        <MenuItem>Upload room</MenuItem>
                      </Link>
                    ) : null}
                    <Link href={"/users/mybookings"}>
                      <MenuItem>My Bookings</MenuItem>
                    </Link>
                    <Link href={"/users/wishlist"}>
                      <MenuItem>Wishlist</MenuItem>
                    </Link>
                    <MenuItem onClick={onLogOut}>Log out</MenuItem>
                  </MenuList>
                </Menu>
              )
            ) : null}
          </HStack>
          {/* 로그인 모달 */}
          <LoginModal isOpen={isLoginOpen} onClose={onLoginClose} />
          {/* 회원가입 모달 */}
          <SignUpModal isOpen={isSignUpOpen} onClose={onSignUpClose} />
        </Box>
      </Flex>

      {/* Mobile Screen 페이지 Links */}
      {isOpen ? (
        <Box pb={4} display={{ base: "inherit", md: "none" }}>
          <Stack as="nav" spacing={2}>
            {navLinks.map((link, index) => (
              <NavLink key={index} {...link} onClose={onClose} />
            ))}
            <Text fontWeight="semibold" color="gray.500">
              Training Sentences
            </Text>
            <Stack pl={2} spacing={1} mt={"0 !important"}>
              {dropdownLinks.map((link, index) => (
                <NavLink key={index} {...link} onClose={onClose} />
              ))}
            </Stack>
          </Stack>
        </Box>
      ) : null}
    </Box>
  );
}
