import { Link, MenuItem, Text, useColorModeValue } from "@chakra-ui/react";

// Dropdown MenuLink Component
interface MenuLinkProps {
  name: string;
  path: string;
  onClose: () => void;
}

export default function MenuLink({ name, path, onClose }: MenuLinkProps) {
  return (
    <Link href={path} onClick={() => onClose()}>
      <MenuItem
        _hover={{
          color: "blue.400",
          bg: useColorModeValue("gray.200", "gray.700")
        }}
      >
        <Text color={useColorModeValue("gray.600", "gray.400")}>{name}</Text>
      </MenuItem>
    </Link>
  );
}
